import React from "react"
import HeaderImage from "../images/header.jpg"

const Header = ({ title, extraPadding = false }) => (
  <header className={`pt-4 ${extraPadding ? 'pb-5' : 'pb-4'} text-white`} style={{backgroundPosition: 'center 86%', backgroundRepeat: 'no-repeat', backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${HeaderImage})`, backgroundSize: 'cover'}}>
    <h1 className="my-5 text-center">{title}</h1>
  </header>
)

export default Header
