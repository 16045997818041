import React, { useRef } from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import Layout from "../components/layout"
import Header from "../components/header"

import Connect4 from "../images/games/connect4.jpg"
import Jenga from "../images/games/jenga.jpg"
import BackyardPong from "../images/games/backyardpong.jpg"
import Cornhole from "../images/games/cornhole.jpg"
import Checkers from "../images/games/checkers.jpg"
import Badminton from "../images/games/badminton.jpg"
import BattlePutt from "../images/games/battleputt.jpg"
import EndzoneToss from "../images/games/endzonetoss.jpg"

import GiantChess from "../images/games/chess.jpg"
import PingPong from "../images/games/pingpong.jpg"
import Pop from "../images/games/pop.jpg"

import Bocce from "../images/games/bocce.jpg"
import Limbo from "../images/games/limbo.jpg"
import KanJam from "../images/games/kanjam.jpg"
import LRC from "../images/games/lrc.jpg"
import YardDice from "../images/games/yarddice.jpg"
import LadderToss from "../images/games/laddertoss.jpg"
import Frisbee from "../images/games/frisbee.jpg"
import LawnDarts from "../images/games/lawndarts.jpg"

import Table from "../images/games/table.jpg"
import RoundTable from "../images/games/roundtable.jpg"
import Chair from "../images/games/chair.jpg"
import Bucket from "../images/games/bucket.jpg"

const GameTile = ({ image, title }) => {
  return <Card className="mb-3">
    <Card.Header className="p-0">
      <div className="ratio ratio-4x3" style={{backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
      </div>
    </Card.Header>
    <Card.Body>
      <Card.Title className="text-center">{title}</Card.Title>
    </Card.Body>
  </Card>
}

const GamesPage = () => {
  const layoutRef = useRef(null);

  return <Layout title="Game Menu" ref={layoutRef}>
    <Header title="Game Menu" extraPadding />

    <Container>
      <section className="bg-primary text-white text-center py-5 px-5" style={{marginTop: '-2rem'}}>
        <Row className="justify-content-center">
          <Col xs={10}>
            <h4 className="text-uppercase mt-4" style={{fontWeight: 800}}>Build Your Bundle</h4>
            <p>All bookings begin with four games from our Favorites list for $140.</p>
            <p>
              Add additional games to your bundle from the Favorites, Deluxe, and Classics sections.<br />
              Prices for additional games are listed for each section below.
            </p>
            <p><i>The four game bundle does not include duplicates of any one game.</i></p>
          </Col>
        </Row>
      </section>
    </Container>

    <section className="text-center py-5">
      <Container>
        <h2 className="mt-5 text-center">Favorites</h2>
        <p className="mb-4 text-center">Select your 4 games from the Favorites list below. Add additional Favorite games for <strong>$30</strong> per game.</p>
        <Row className="justify-content-center">
          <Col xs={6} sm={4} lg={3}><GameTile image={Connect4} title="Giant 4-To-Score" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Jenga} title="Giant Tumble Tower" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={BackyardPong} title="Backyard Pong" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Cornhole} title="Corn Hole" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Checkers} title="Giant Checkers" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Badminton} title="Badminton" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={BattlePutt} title="Battle Putt" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={EndzoneToss} title="Endzone Toss" /></Col>
        </Row>

        <h2 className="mt-5 text-center">Deluxe Add-ons</h2>
        <p className="mb-4 text-center">Add any of our Deluxe games to your booking for an additional <strong>$40</strong> per game.</p>
        <Row className="justify-content-center">
          <Col xs={6} sm={4} lg={3}><GameTile image={GiantChess} title="Giant Chess" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={PingPong} title="6x3' Ping Pong Table Set" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Pop} title="Pop-A-Shot" /></Col>
        </Row>

        <h2 className="mt-5 text-center">Classic Add-ons</h2>
        <p className="mb-4 text-center">Add any of our Classic games to your booking for an additional <strong>$10</strong> per game.</p>
        <Row className="justify-content-center">
          <Col xs={6} sm={4} lg={3}><GameTile image={Bocce} title="Bocce" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Limbo} title="Limbo" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={YardDice} title="Yard Dice (Yahtzee)" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={KanJam} title="KanJam" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={LadderToss} title="Ladder Toss" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={LRC} title="Jumbo LRC" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Frisbee} title="Frisbee Bottle Catch" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={LawnDarts} title="Lawn Darts" /></Col>
        </Row>

        <h2 className="mt-5 text-center">Party Supply Add-ons</h2>
        <p className="mb-4 text-center">Need a place to sit after all those games? We have party supplies you can add to your game rental!</p>
        <Row className="justify-content-center">
          <Col xs={6} sm={4} lg={3}><GameTile image={Table} title="6' Table ($20)" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={RoundTable} title="2' Round Table ($10)" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Chair} title="Chairs (4 for $20)" /></Col>
          <Col xs={6} sm={4} lg={3}><GameTile image={Bucket} title="Drink Buckets ($3)" /></Col>
        </Row>

        <h3 className="mt-5 text-center">Just so you know...</h3>
        <p className="mt-4">
          For special requests, including renting multiple sets of the same game, please contact us.<br />
          Games may vary in color and style from the photo based on what is available on your date.<br />
          Party supplies can only be included with a game bundle.
        </p>
      </Container>
    </section>
  </Layout>
}

export default GamesPage
